
    import { IonHeader, IonToolbar, IonTitle, IonGrid, IonRow, IonCol } from '@ionic/vue';

    export default {
        name: 'HeaderOne',
        components: { IonHeader, IonToolbar, IonTitle, IonGrid, IonRow, IonCol },
        props: {
          name: {
            type: String,
            default: 'Name'
          },
        }
    }
