<template>
    <ion-header>
        <ion-toolbar color="primary">
            <ion-grid>
                <ion-row class="ion-align-items-center">
                    <ion-col size="small">
                        <img src="../../public/assets/icon/logo-header.svg" alt="" />
                    </ion-col>
                    <ion-col>
                        <ion-title>{{ name }}</ion-title>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-toolbar>
    </ion-header>
</template>

<script lang="ts">
    import { IonHeader, IonToolbar, IonTitle, IonGrid, IonRow, IonCol } from '@ionic/vue';

    export default {
        name: 'HeaderOne',
        components: { IonHeader, IonToolbar, IonTitle, IonGrid, IonRow, IonCol },
        props: {
          name: {
            type: String,
            default: 'Name'
          },
        }
    }
</script>

<style scoped>

</style>
