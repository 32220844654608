<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('service')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{ $t('service')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-list>

          <!-- Interne Links -->
          <template
          v-for="service in services"
          :key="service.id"
          >
          <ion-item v-bind:router-link="service.href">
            <!--<ion-item button onclick="window.open('{{ service.href }}','_system', 'location=yes'); return false;">-->
              <!--<a class="item" href="#" onclick="window.open('{{ service.href }}','_system', 'location=yes'); return false;">
                Open a Browser
              </a>-->
              <ion-icon
                slot="start"
                :icon="service.icon"
                color="primary"
              />
              <ion-label>{{ $t(service.name) }}</ion-label>
            </ion-item>
          </template>

          <!-- Externe Links -->
          <template
          v-for="link in links"
          :key="link.id"
          >
          <ion-item button :href="link.href">
            <!--<ion-item button onclick="window.open('{{ service.href }}','_system', 'location=yes'); return false;">-->
              <!--<a class="item" href="#" onclick="window.open('{{ service.href }}','_system', 'location=yes'); return false;">
                Open a Browser
              </a>-->
              <ion-icon
                slot="start"
                :icon="link.icon"
                color="primary"
              />
              <ion-label>{{ $t(link.name) }}</ion-label>
            </ion-item>
          </template>

      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { chevronForward, bulbOutline, languageOutline, briefcaseOutline, logoDiscord, trainOutline, peopleOutline, mapOutline, medkitOutline } from 'ionicons/icons';
import HeaderOne from '@/components/HeaderOne.vue'

export default  {
  name: 'Services',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, HeaderOne, IonList, IonIcon, IonItem, IonLabel },
  data: function() {
    return {
      services: [
        {
          id: 1,
          icon: peopleOutline,
          name: 'contacts',
          href: '/tabs/ansprechpartner',
        },
        {
          id: 2,
          icon: trainOutline,
          name: 'vbb',
          href: '/tabs/vbb',
        },
        {
          id: 3,
          icon: mapOutline,
          name: 'map',
          href: '/tabs/map',
         },
         {
          id: 4,
          icon: medkitOutline,
          name: 'covid.link_title',
          href: '/tabs/covid',
        },
      ],
      links: [
        {
          id: 1,
          icon: bulbOutline,
          name: 'zgt',
          href: 'https://zgt.th-brandenburg.de/',
        },
        {
          id: 2,
          icon: briefcaseOutline,
          name: 'zskm',
          href: '/tabs/service',
        },
        {
          id: 3,
          icon: languageOutline,
          name: 'zis',
          href: '/tabs/service',
        },
        {
          id: 4,
          icon: logoDiscord,
          name: 'discord',
          href: 'https://discord.gg/3Y2jx5bT',
        }
      ]
    }
},
  setup() {
    return {
      chevronForward,
      bulbOutline,
      briefcaseOutline,
      logoDiscord,
      trainOutline,
      peopleOutline,
      mapOutline,
      medkitOutline,
    }
  }
}
</script>
